export default [
  {
    id: 1,
    text: "I am the Lord your God and I never change.",
    ref: "Malachi 3:6",
  },
  {
    id: 2,
    text: "I am full of mercy and grace and I overflow with love.",
    ref: "Psalm 103:8",
  },
  {
    id: 3,
    text: "The intentions of My heart will remain steadfast forever.",
    ref: "Psalm 33:11",
  },
  {
    id: 4,
    text: "I created you in My own image with My special blessing.",
    ref: "Genesis 1:27-28",
  },
  {
    id: 5,
    text: "I chose you to be adopted into My family before creation.",
    ref: "Epshesians 1:4-5",
  },
  {
    id: 6,
    text: "I delivered you from darkness into the kingdom of My beloved Son.",
    ref: "Colossians 1:12-13",
  },
  {
    id: 7,
    text:
      "I have given you eternal life because you have believed in Jesus Christ.",
    ref: "John 3:16",
  },
  {
    id: 8,
    text:
      "I sent the spirit of My Son into your heart so you could call Me Father.",
    ref: "Galatians 4:6",
  },
  {
    id: 9,
    text:
      "Since you are in Christ, I have made you an heir of all my promises.",
    ref: "Galatians 3:29",
  },
  {
    id: 10,
    text: "I have given you a new heart and put My own Spirit in you.",
    ref: "Ezekiel 36:26-27",
  },
  {
    id: 11,
    text: "My plan for your future is filled with hope.",
    ref: "Jeremiah 29:11",
  },
  {
    id: 12,
    text: "I am at work in your life through the desires of your heart.",
    ref: "Philippians 2:13",
  },
  {
    id: 13,
    text:
      "I will give you rest in green pastures and lead you to still waters.",
    ref: "Psalm 23:2",
  },
  {
    id: 14,
    text: "I give My Spirit in unlimited measure.",
    ref: "John 3:34",
  },
  {
    id: 15,
    text: "I take hold of your hand to keep you from falling.",
    ref: "Psalm 37:24",
  },
  {
    id: 16,
    text: "I will provide every good thing you need to do My will.",
    ref: "Hebrews 13:20-21",
  },
  {
    id: 17,
    text: "I will discipline you in love, as a father who loves His children.",
    ref: "Proverbs 3:11-12",
  },
  {
    id: 18,
    text: "My power will rest on you when you are weak.",
    ref: "2 Corinthians 12:9",
  },
  {
    id: 19,
    text: "I will bless your life and keep watch over you always.",
    ref: "Numbers 6:24",
  },
  {
    id: 20,
    text: "If you search for Me with all your heart, you will find me.",
    ref: "Jeremiah 29:13",
  },
  {
    id: 21,
    text: "I will shield your life and deliver you from the wicked.",
    ref: "Psalm 97:10",
  },
  {
    id: 22,
    text: "Cast all your worries on Me, for I really care about you.",
    ref: "1 Peter 5:7",
  },
  {
    id: 23,
    text: "I will not let you be tested beyond what you can endure.",
    ref: "1 Corinthians 10:13",
  },
  {
    id: 24,
    text: "My love will persevere through every situation.",
    ref: "1 Corinthians 13:4-7",
  },
  {
    id: 25,
    text: "I will not withhold anything good from those with an upright heart.",
    ref: "Psalm 84:11",
  },
  {
    id: 26,
    text: "I look after foreigners and I help the fatherless and the widow.",
    ref: "Psalm 146:9",
  },
  {
    id: 27,
    text: "I will guard those who have a childlike heart.",
    ref: "Psalm 116:6",
  },
  {
    id: 28,
    text: "You are more than a conqueror through My love in Jesus Christ.",
    ref: "Romans 8:37",
  },
  {
    id: 29,
    text:
      "When Jesus appears, you will receive a crown of glory that will never dim.",
    ref: "1 Peter 5:4",
  },
  {
    id: 30,
    text:
      "At the end of the age, My righteous ones will shine like the sun in My kingdom.",
    ref: "Matthew 13:43",
  },
  {
    id: 31,
    text: "Not one of my promises will ever fail you.",
    ref: "Joshua 23:14",
  },
  {
    id: 32,
    text: "I am seated in the heavens and My kingdom reigns over all.",
    ref: "Psalms 103:19",
  },
  {
    id: 33,
    text:
      "All My plans will be fulfilled, for I know the end from the beginning.",
    ref: "Isaiah 46:10",
  },
  {
    id: 34,
    text: "I knew you before you were born and I designed you for My purposes.",
    ref: "Jeremiah 1:5",
  },
  {
    id: 35,
    text: "You are holy and free from blame because of Jesus Christ’s death. ",
    ref: "Colossians 1:21-22",
  },
  {
    id: 36,
    text: "I have blotted out your sins and dissolved them like the mist.",
    ref: "Isaiah 44:22",
  },
  {
    id: 37,
    text:
      "I have sealed you with My Spirit to guarantee your coming inheritance.",
    ref: "Ephesians 1:13-14",
  },
  {
    id: 38,
    text: "I will be a real Father to you.",
    ref: "2 Corinthians 6:18",
  },
  {
    id: 39,
    text: "I will look after you and teach you the way that is best.",
    ref: "Psalm 32:8",
  },
  {
    id: 40,
    text: "Trust in Me with all your heart and I will guide you.",
    ref: "Proverbs 3:5-6",
  },
  {
    id: 41,
    text: "I will give you peace at all times and in every situation.",
    ref: "2 Thessalonians 3:16",
  },
  {
    id: 42,
    text: "Call on Me when you are in trouble and I will rescue you.",
    ref: "Psalm 91:15",
  },
  {
    id: 43,
    text: "I am your shelter and a place of safety from your enemies.",
    ref: "Psalm 61:3",
  },
  {
    id: 44,
    text: "If you wait for Me, I will work on your behalf.",
    ref: "Isaiah 64:4",
  },
  {
    id: 45,
    text: "My love will never fail you.",
    ref: "1 Corinthians 13:8",
  },
  {
    id: 46,
    text: "I am your Shepherd and I will meet all your needs.",
    ref: "Psalm 23:1",
  },
  {
    id: 47,
    text: "I will keep you safe because no one can snatch you out of My hand.",
    ref: "John 10:29",
  },
  {
    id: 48,
    text: "I will heal your broken heart and mend all your wounds.",
    ref: "Psalm 147:3",
  },
  {
    id: 49,
    text: "I will bless you in times of mourning with My comfort.",
    ref: "Matthew 5:4",
  },
  {
    id: 50,
    text: "You will find freedom wherever My Spirit dwells.",
    ref: "2 Corinthians 3:17",
  },
  {
    id: 51,
    text: "You can know and depend on the love that I have for you.",
    ref: "1 John 4:16",
  },
  {
    id: 52,
    text: "You can trust in Me for I will never forsake you.",
    ref: "Psalm 9:10",
  },
  {
    id: 53,
    text: "I put people who are lonely in families.",
    ref: "Psalm 68:6",
  },
  {
    id: 54,
    text: "I hear you when you ask for anything according to my will.",
    ref: "1 John 5:14",
  },
  {
    id: 55,
    text: "I will reward those who diligently seek me with a heart of faith.",
    ref: "Hebrews 11:6",
  },
  {
    id: 56,
    text: "I will sanctify you and keep you blameless until Jesus returns.",
    ref: "1 Thessalonians 5:23-24",
  },

  {
    id: 57,
    text: "When your body fails, you have an external home waiting for you.",
    ref: "2 Corinthians 5:1",
  },
  {
    id: 58,
    text: "Those who overcome will sit with My Son on His throne.",
    ref: "Revelation 3:21",
  },
  {
    id: 59,
    text: "My promise of life is for you and for your family.",
    ref: "Acts 2:39",
  },
  {
    id: 60,
    text: "All My promises are fulfilled in Christ.",
    ref: "2 Corinthians 1:20",
  },
  {
    id: 61,
    text: "The earth belongs to Me and all that is in it.",
    ref: "Psalm 24:1",
  },
  {
    id: 62,
    text: "My throne will stand forever and justice will reign in My kingdom.",
    ref: "Psalm 45:6",
  },
  {
    id: 63,
    text:
      "The grass will dry up and the flowers will fall, but My Word will endure forever.",
    ref: "1 Peter 1:24-25",
  },
  {
    id: 64,
    text: "Though the mountains vanish, My unending love will never leave you.",
    ref: "Isaiah 54:10",
  },
  {
    id: 65,
    text: "I have called you out of darkness into My glorious light.",
    ref: "1 Peter 2:9",
  },
  {
    id: 66,
    text: "I have chosen the weak things of this world to confound the strong.",
    ref: "1 Corinthians 1:27",
  },
  {
    id: 67,
    text: "I delight to reveal My kingdom to those with a childlike heart.",
    ref: "Matthew 11:25-26",
  },
  {
    id: 68,
    text: "I have brought you close through the blood of Christ.",
    ref: "Ephesians 2:13",
  },
  {
    id: 69,
    text: "Your old life has died and your new life is hid with Christ in Me.",
    ref: "Colossians 3:3",
  },
  {
    id: 70,
    text:
      "The same Spirit that raised Jesus from the dead will also give life to you.",
    ref: "Romans 8:11",
  },

  {
    id: 71,
    text: "I have blessed you in Christ with every heavenly blessing.",
    ref: "Ephesians 1:3",
  },
  {
    id: 72,
    text: "Commit all that you do to Me and your plans will be successful.",
    ref: "Proverbs 16:3",
  },
  {
    id: 73,
    text: "I am with you and I will help you because I am your God.",
    ref: "Isaiah 41:10",
  },
  {
    id: 74,
    text: "I will never abandon you.",
    ref: "Hebrews 13:5",
  },
  {
    id: 75,
    text: "I see all your hardships and I care about your suffering.",
    ref: "Psalm 31:7",
  },
  {
    id: 76,
    text: "When you pass through turbulent waters, I will be close to you.",
    ref: "Isaiah 43:2",
  },
  {
    id: 77,
    text: "My anointing will teach you all you need to know.",
    ref: "1 John 2:27",
  },
  {
    id: 78,
    text: "If you live in My secret place, you will find rest under My shadow.",
    ref: "Psalm 91:1",
  },
  {
    id: 79,
    text: "I will give you My strength to help you stand in high places.",
    ref: "2 Samuel 22:33-34",
  },
  {
    id: 80,
    text: "Thousands may fall around you, but you will not be harmed.",
    ref: "Psalm 91:7",
  },
  {
    id: 81,
    text: "I provide food for the hungry and justice for the oppressed.",
    ref: "Psalm 146:7",
  },
  {
    id: 82,
    text: "My divine power will give you all you need to live a good life.",
    ref: "2 Peter 1:3",
  },
  {
    id: 83,
    text: "My mercy will overrule judgement.",
    ref: "James 2:13",
  },
  {
    id: 84,
    text: "I will cause all things to ultimately work for your good.",
    ref: "Romans 8:28",
  },

  {
    id: 85,
    text: "Humble yourself before Me and in time I will exalt you.",
    ref: "1 Peter 5:6",
  },
  {
    id: 86,
    text: "I have carved your name in the palms of my hands.",
    ref: "Isaiah 49:16",
  },
  {
    id: 87,
    text: "Never tire of doing good, for in time you will reap a harvest.",
    ref: "Galatians 6:9",
  },
  {
    id: 88,
    text:
      "An eternal crown awaits you at the finish line, so keep running your race.",
    ref: "1 Corinthians 9:24-25",
  },
  {
    id: 89,
    text: "I prepared a kingdom inheritance for you when I created the world.",
    ref: "Matthew 25:34",
  },
  {
    id: 90,
    text: "It is precious for Me to see My faithful ones come home.",
    ref: "Psalm 116:15",
  },
  {
    id: 91,
    text: "I will not forget My promises because I am a merciful God",
    ref: "Deuteronomy 4:31",
  },
  {
    id: 92,
    text: "My kingdom is everlasting and My dominion will endure forever.",
    ref: "Psalm 145:13",
  },
  {
    id: 93,
    text: "My eternal Word is anchored in the heavens.",
    ref: "Psalm 119:89",
  },
  {
    id: 94,
    text: "I am a gracious God who is righteous and full of mercy.",
    ref: "Psalm 116:5",
  },
  {
    id: 95,
    text: "I created all things for My pleasure.",
    ref: "Revelation 4:11",
  },
  {
    id: 96,
    text: "I am your Father and you are My handiwork.",
    ref: "Isaiah 64:8",
  },
  {
    id: 97,
    text: "I will protect and carry you all the days of your life.",
    ref: "Isaiah 46:4",
  },
  {
    id: 98,
    text:
      "I have chosen you to be like My Son, so you could be part of My family.",
    ref: "Romans 8:29",
  },

  {
    id: 99,
    text: "I have cancelled all your sin and made you alive in Jesus Christ.",
    ref: "Colossians 2:13-14",
  },
  {
    id: 100,
    text: "I have raised you up with Christ to sit in heavenly places.",
    ref: "Ephesians 2:5-6",
  },
  {
    id: 101,
    text: "The good things that I have planned for you are too many to count.",
    ref: "Psalm 40:5",
  },
  {
    id: 102,
    text: "I will restore and refresh your weary soul.",
    ref: "Psalm 23:3",
  },
  {
    id: 103,
    text: "I will always build you up, not pull you down.",
    ref: "Jeremiah 24:6",
  },
  {
    id: 104,
    text: "I have made you a member of My own household.",
    ref: "Ephesians 2:19",
  },
  {
    id: 105,
    text: "If you make your home in Me, then no evil will come your way.",
    ref: "Psalm 91:9-10",
  },
  {
    id: 106,
    text: "If you delight in My Word, you will be fruitful and prosperous.",
    ref: "Psalm 1:1-3",
  },
  {
    id: 107,
    text: "I will cover you all day long as you rest between My shoulders.",
    ref: "Deuteronomy 33:12",
  },
  {
    id: 108,
    text: "Come close to Me and I will come close to you.",
    ref: "James 4:8",
  },
  {
    id: 109,
    text: "You can trust in Me, for I am your strength and your song.",
    ref: "Isaiah 12:2",
  },
  {
    id: 110,
    text: "My gentleness will enlarge your life.",
    ref: "2 Samuel 22:36",
  },
  {
    id: 111,
    text: "I do not show favoritism in My family.",
    ref: "Ephesians 6:9",
  },
  {
    id: 112,
    text: "I have chosen the poor to be rich in faith and heirs of My kingdom.",
    ref: "James 2:5",
  },
  {
    id: 113,
    text: "Ask Me for wisdom and I will generously give it to you.",
    ref: "James 1:5",
  },
  {
    id: 114,
    text: "I will give you power to know the vastness of My immeasurable love.",
    ref: "Ephesians 3:17-19",
  },
  {
    id: 115,
    text: "Wait patiently for Me and I will hear your cry.",
    ref: "Psalm 40:1",
  },
  {
    id: 116,
    text:
      "Even when you are weak in your faith, I will remain faithful to you.",
    ref: "2 Timothy 2:13",
  },
  {
    id: 117,
    text: "Seek My kingdom first and everything you need will be given to you.",
    ref: "Luke 12:31",
  },
  {
    id: 118,
    text:
      "My spirit will lead you into all truth and show you what is to come.",
    ref: "John 16:12-13",
  },
  {
    id: 119,
    text: "One day, I will wipe away every tear and take away all your pain.",
    ref: "Revelations 21:4",
  },
  {
    id: 120,
    text:
      "My everlasting joy and gladness will drive all sorrow and mourning away.",
    ref: "Isaiah 51:11",
  },
  {
    id: 121,
    text:
      "You can hold on to the hope that I will not change what I have promised.",
    ref: "Hebrews 6:17-18",
  },
  {
    id: 122,
    text: "My Spirit made you and My breath gives you life.",
    ref: "Job 33:4",
  },
  {
    id: 123,
    text: "There is no place you can go to escape My presence.",
    ref: "Psalm 139:7-10",
  },
  {
    id: 124,
    text:
      "All your days are known to Me and your inheritance is secure forever.",
    ref: "Psalm 37:18",
  },
  {
    id: 125,
    text: "I gently formed you in your mother's womb.",
    ref: "Psalm 139:13",
  },
  {
    id: 126,
    text: "I am delighted to give you My kingdom.",
    ref: "Luke 12:32",
  },
  {
    id: 127,
    text: "My face will shine upon you all the days of your life.",
    ref: "Numbers 6:25",
  },
  {
    id: 128,
    text: "My word will brighten your steps and light your path.",
    ref: "Psalm 119:105",
  },
  {
    id: 129,
    text: "My Son took upon Himself all your sicknesses and diseases.",
    ref: "Matthew 8:16-17",
  },
  {
    id: 130,
    text: "My Spirit will help you in your weakness.",
    ref: "Romans 8:26",
  },
  {
    id: 131,
    text: "You can do all things through Jesus Christ who gives you strength.",
    ref: "Philippians 4:13",
  },
  {
    id: 132,
    text: "Be courageous, for I am with you and I will never fail you.",
    ref: "Deuteronomy 31:6",
  },
  {
    id: 133,
    text: "I am a very present help for you in times of trouble.",
    ref: "Psalm 46:1",
  },
  {
    id: 134,
    text: "I will keep watch over you and guard you forever.",
    ref: "Psalm 12:7",
  },
  {
    id: 135,
    text: "I hear your voice when you call to Me in the morning.",
    ref: "Psalm 5:3",
  },
  {
    id: 136,
    text: "All that I have is yours because you belong to Jesus.",
    ref: "John 16:15",
  },
  {
    id: 137,
    text: "My goodness and mercy will chase after you all your life.",
    ref: "Psalm 23:6",
  },
  {
    id: 138,
    text: "I will teach your children My ways and give them great peace.",
    ref: "Isaiah 54:13",
  },
  {
    id: 139,
    text: "I will not forget the love you have shown Me by serving others.",
    ref: "Hebrews 6:10",
  },
  {
    id: 140,
    text: "I will repay those who are kind to the poor.",
    ref: "Proverbs 19:17",
  },
  {
    id: 141,
    text: "All My blessings will overtake those who obey Me.",
    ref: "Deuteronomy 28:2",
  },
  {
    id: 142,
    text: "I know what you need before you even ask.",
    ref: "Matthew 6:8",
  },
  {
    id: 143,
    text: "I open prison doors and set captives free.",
    ref: "Isaiah 61:1",
  },
  {
    id: 144,
    text: "I will rescue the godly from all their troubles.",
    ref: "2 Peter 2:9",
  },
  {
    id: 145,
    text: "My displeasure may last a moment, but My favor is yours for life.",
    ref: "Psalm 30:5",
  },
  {
    id: 146,
    text: "I will fill those who hunger and thirst for My righteousness.",
    ref: "Matthew 5:6",
  },
  {
    id: 147,
    text: "I will show you all the mysteries of My kingdom.",
    ref: "Matthew 13:11",
  },
  {
    id: 148,
    text: "Those who overcome will eat from the tree of life in paradise.",
    ref: "Revelation 2:7",
  },
  {
    id: 149,
    text: "I have prepared amazing things for those who love Me.",
    ref: "1 Corinthians 2:9",
  },
  {
    id: 150,
    text: "Those who are pure in heart will see My face.",
    ref: "Matthew 5:8",
  },
  {
    id: 151,
    text: "I began the good work in you and I will finish it.",
    ref: "Philippians 1:6",
  },
  {
    id: 152,
    text: "You can rest in My promise of eternal life, for I cannot lie.",
    ref: "Titus 1:2",
  },
  {
    id: 153,
    text:
      "My love is everlasting and My faithfulness extends to all generations.",
    ref: "Psalm 100:5",
  },
  {
    id: 154,
    text:
      "My righteousness towers like the mountains and My justice goes deep as the sea.",
    ref: "Psalm 36:6",
  },
  {
    id: 155,
    text:
      "My Word will not return to Me until it accomplishes what I intended.",
    ref: "Isaiah 55:10-11",
  },
  {
    id: 156,
    text: "When I open My hand, I provide for all that I created.",
    ref: "Psalm 145:16",
  },
  {
    id: 157,
    text: "I love you as deeply as a father loves his children.",
    ref: "Psalm 103:13",
  },
  {
    id: 158,
    text: "I will not abandon you, for I am glad to make you My very own.",
    ref: "1 Samuel 12:22",
  },
  {
    id: 159,
    text: "My loving kindness is more precious than life.",
    ref: "Psalm 63:3",
  },
  {
    id: 160,
    text:
      "I made you holy by the once for all sacrifice of My Son, Jesus Christ.",
    ref: "Hebrews 10:10",
  },
  {
    id: 161,
    text:
      "I have removed your sins from you as far as the east is from the west.",
    ref: "Psalm 103:12",
  },
  {
    id: 162,
    text: "You can enjoy freedom because Christ has set you free.",
    ref: "Galatians 5:1",
  },
  {
    id: 163,
    text:
      "I have anointed you with My Spirit as a down payment of what is yet to come.",
    ref: "1 Corinthians 2:21-22",
  },
  {
    id: 164,
    text: "Every good gift that you receive comes directly from My hand.",
    ref: "James 1:17",
  },
  {
    id: 165,
    text: "Nothing will be impossible for those who have even a little faith.",
    ref: "Matthew 17:19-20",
  },
  {
    id: 166,
    text:
      "Call out to Me and I will show you wonderful things you do not know.",
    ref: "Jeremiah 33:3",
  },
  {
    id: 167,
    text: "I will reveal My secrets to those who fear Me.",
    ref: "Psalm 25:14",
  },
  {
    id: 168,
    text: "I am able to do more for you than you could possibly imagine.",
    ref: "Ephesians 3:20",
  },
  {
    id: 169,
    text:
      "When you walk through the fires of adversity, you will not be burned.",
    ref: "Isaiah 43:2",
  },
  {
    id: 170,
    text: "No weapon created for your harm will succeed against you.",
    ref: "Isaiah 54:17",
  },
  {
    id: 171,
    text:
      "I will give you a garment of praise in exchange for your spirit of heaviness.",
    ref: "Isaiah 61:3",
  },
  {
    id: 172,
    text: "My spirit will rest on you when you suffer for Christ’s sake.",
    ref: "1 Peter 4:14",
  },
  {
    id: 173,
    text: "My goodness will lead you to a change of heart.",
    ref: "Romans 2:4",
  },
  {
    id: 174,
    text: "I will bless the peacemakers and call them My children.",
    ref: "Matthew 5:9",
  },
  {
    id: 175,
    text: "I will protect the fatherless, and widows can trust in Me.",
    ref: "Jeremiah 49:11",
  },
  {
    id: 176,
    text: "I will provide justice for everyone who is mistreated.",
    ref: "Psalm 103:6",
  },
  {
    id: 177,
    text: "My name is a strong tower that you can run to and find safety.",
    ref: "Proverbs 18:10",
  },
  {
    id: 178,
    text: "When you leave your body, you will be at home in My presence.",
    ref: "2 Corinthians 5:8",
  },
  {
    id: 179,
    text:
      "The world and its passions will disappear, but those who do My will shall live forever.",
    ref: "1 John 2:17",
  },
  {
    id: 180,
    text: "I will rescue you from evil and safely deliver you into My kingdom.",
    ref: "2 Timothy 4:18",
  },
  {
    id: 181,
    text:
      "There is a crown of righteousness waiting for everyone who loves My Son.",
    ref: "2 Timothy 4:8",
  },
  {
    id: 182,
    text:
      "If you persist and do My will, you will receive what I have promised.",
    ref: "Hebrews 10:36",
  },
  {
    id: 183,
    text:
      "I made the heavens by My Word and I breathed the stars into existence.",
    ref: "Psalm 33:6",
  },
  {
    id: 184,
    text:
      "I am faithful to keep My covenant of kindness to a thousand generations.",
    ref: "Deuteronomy 7:9",
  },
  {
    id: 185,
    text: "All of mankind can find protection in the shadow of My wings.",
    ref: "Psalm 36:7",
  },
  {
    id: 186,
    text: "I am a father to the fatherless and I defend the cause of widows.",
    ref: "Psalm 68:5",
  },
  {
    id: 187,
    text: "I will not abandon My own inheritance.",
    ref: "Psalm 94:14",
  },
  {
    id: 188,
    text: "I am the Lord your God who heals all of your disease.",
    ref: "Exodus 15:26",
  },
  {
    id: 189,
    text: "My love for you will last forever.",
    ref: "Jeremiah 31:3",
  },
  {
    id: 190,
    text: "I give good gifts to My children when they ask.",
    ref: "Matthew 7:11",
  },
  {
    id: 191,
    text: "Jesus is not ashamed to be called your big brother.",
    ref: "Hebrews 2:11",
  },
  {
    id: 192,
    text: "I forgive sins and I love to show mercy.",
    ref: "Micah 7:18",
  },
  {
    id: 193,
    text: "I have poured My love into your heart through My Spirit.",
    ref: "Romans 5:5",
  },
  {
    id: 194,
    text: "I have put My law within you and have written it on your heart.",
    ref: "Jeremiah 31:33",
  },
  {
    id: 195,
    text:
      "I will meet your every need through My eternal riches in Jesus Christ.",
    ref: "Philippians 4:19",
  },
  {
    id: 196,
    text: "I will shepherd My inheritance and carry them forever.",
    ref: "Psalm 28:9",
  },
  {
    id: 197,
    text: "Do not be sad, for My joy is your strength.",
    ref: "Nehemiah 8:10",
  },
  {
    id: 198,
    text: "My perfect love will banish fear from your heart.",
    ref: "1 John 4:18",
  },
  {
    id: 199,
    text: "I will save you in the midst of your troubles.",
    ref: "Psalm 138:7",
  },
  {
    id: 200,
    text: "I will blanket My protection over all who trust in Me.",
    ref: "Psalm 5:11",
  },
  {
    id: 201,
    text: "My armour will help you stand against the plans of the enemy.",
    ref: "Ephesians 6:11",
  },
  {
    id: 202,
    text: "I will turn your mourning into dancing and surround you with joy.",
    ref: "Psalm 30:11",
  },
  {
    id: 203,
    text: "I will restore your health and heal all your hurts.",
    ref: "Jeremiah 30:17",
  },
  {
    id: 204,
    text:
      "I will strengthen you with feet like a deer so you can reach great heights.",
    ref: "Habakkuk 3:19",
  },
  {
    id: 205,
    text: "If you share with the needy, My glory will guard your back.",
    ref: "Isaiah 58:7-8",
  },
  {
    id: 206,
    text:
      "If you enter into My rest, you will find rest from all your striving.",
    ref: "Hebrews 4:9-10",
  },
  {
    id: 207,
    text: "I am near to you whenever you cry out.",
    ref: "Deuteronomy 4:7",
  },
  {
    id: 208,
    text: "When problems arise, call to Me and I will answer you.",
    ref: "Psalm 86:7",
  },
  {
    id: 209,
    text: "Don’t worry, I will take care of you.",
    ref: "Matthew 6:25-26",
  },
  {
    id: 210,
    text: "Those who overcome will be pillars in My temple forever.",
    ref: "Revelation 3:12",
  },
  {
    id: 211,
    text:
      "You are My children now and you will be just like Jesus when He appears.",
    ref: "1 John 3:2",
  },
  {
    id: 212,
    text: "One day, you will see My face and My name will be on your forehead.",
    ref: "Revelation 22:4",
  },
  {
    id: 213,
    text:
      "Every promise that I make is true, for I do not lie or change My mind.",
    ref: "Numbers 23:19",
  },
  {
    id: 214,
    text: "The heavens declare My glory and reveal My handiwork.",
    ref: "Psalm 19:1",
  },
  {
    id: 215,
    text: "I rule the earth and all the kings belong to Me.",
    ref: "Psalm 47:7-9",
  },
  {
    id: 216,
    text: "You can trust in My faithfulness because My Word is true. ",
    ref: "Psalm 33:4",
  },
  {
    id: 217,
    text: "I am good and My mercy is everlasting.",
    ref: "1 Chronicles 16:34",
  },
  {
    id: 218,
    text: "I have cared for you from the moment you were born.",
    ref: "Psalm 71:6",
  },
  {
    id: 219,
    text: "I have even numbered each hair on your head.",
    ref: "Matthew 10:30",
  },
  {
    id: 220,
    text:
      "Through faith in Christ, you can boldly approach Me with confidence.",
    ref: "Ephesians 3:12",
  },
  {
    id: 221,
    text:
      "If you walk in the light, the blood of My Son Jesus will cleanse you from all sin.",
    ref: "1 John 1:7",
  },
  {
    id: 222,
    text: "Trust in Me, and do not be afraid of what others can do to you.",
    ref: "Psalm 56:11",
  },
  {
    id: 223,
    text: "I will be your security and a safe place for your children.",
    ref: "Proverbs 14:26",
  },
  {
    id: 224,
    text: "I am your shield, your glory, and the lifter of your head.",
    ref: "Psalm 3:3",
  },
  {
    id: 225,
    text: "I will gladly give the Holy Spirit to those who will ask.",
    ref: "Luke 11:13",
  },
  {
    id: 226,
    text: "Forgiveness and healing are among My many benefits.",
    ref: "Psalm 103:2-3",
  },
  {
    id: 227,
    text: "If you rest in Me and wait patiently, you will inherit the land.",
    ref: "Psalm 37:7-9",
  },
  {
    id: 228,
    text: "I give power to the exhausted and I strengthen the weak.",
    ref: "Isaiah 40:29",
  },
  {
    id: 229,
    text: "If I am for you, no one can stand against you.",
    ref: "Romans 8:31",
  },
  {
    id: 230,
    text: "Though you have many plans, My purpose will be fulfilled.",
    ref: "Proverbs 19:21",
  },
  {
    id: 231,
    text: "If two of you agree in prayer, I will do whatever you ask.",
    ref: "Matthew 18:19",
  },
  {
    id: 232,
    text: "I will make you fruitful in your old age.",
    ref: "Psalm 92:13-14",
  },
  {
    id: 233,
    text: "Do not despise My discipline, for in time, I will heal your wounds.",
    ref: "Job 5:17-18",
  },
  {
    id: 234,
    text: "I bind up the broken hearted and I will comfort all who mourn.",
    ref: "Isaiah 61:1-2",
  },
  {
    id: 235,
    text: "I will comfort you just like a mother would.",
    ref: "Isaiah 66:13",
  },
  {
    id: 236,
    text:
      "Your sadness may last for a night, but joy will come in the morning.",
    ref: "Psalm 30:5",
  },
  {
    id: 237,
    text: "I am pleased when you share with others.",
    ref: "Hebrews 13:16",
  },
  {
    id: 238,
    text: "I will keep you safe from the evil one.",
    ref: "1 John 5:18",
  },
  {
    id: 239,
    text: "I will show mercy to those who are merciful to others.",
    ref: "Matthew 5:7",
  },
  {
    id: 240,
    text: "I bless My righteous ones and shield them with My favor.",
    ref: "Psalm 5:12",
  },
  {
    id: 241,
    text: "I will anoint you with oil so your cup overflows.",
    ref: "Psalm 23:5",
  },
  {
    id: 242,
    text: "You can celebrate because your name is written in heaven.",
    ref: "Luke 10:20",
  },
  {
    id: 243,
    text: "There is a special room in My house prepared just for you.",
    ref: "John 14:12",
  },
  {
    id: 244,
    text:
      "You can look forward to the new heaven and earth that I have promised.",
    ref: "2 Peter 3:13",
  },
  {
    id: 245,
    text: "I spoke the universe into existence.",
    ref: "Genesis 1:1",
  },
  {
    id: 246,
    text: "I give life and breath to all things.",
    ref: "Acts 17:24-25",
  },
  {
    id: 247,
    text: "You can rest in My love, for I have power to save you.",
    ref: "Zephaniah 3:17",
  },
  {
    id: 248,
    text: "My thoughts toward you outnumber the sand on the seashore.",
    ref: "Psalm 139:17-18",
  },
  {
    id: 249,
    text: "All the days of your life are written in My special book.",
    ref: "Psalm 139:16",
  },
  {
    id: 250,
    text: "I will carry you in My arms just like a father carries his child.",
    ref: "Deuteronomy 1:31",
  },
  {
    id: 251,
    text:
      "I will give you a double portion of your inheritance in exchange for your shame.",
    ref: "Isaiah 61:7",
  },
  {
    id: 252,
    text: "I made you alive in Jesus simply because I love you.",
    ref: "Ephesians 2:4-5",
  },
  {
    id: 253,
    text: "I will forgive your sins and then forget them.",
    ref: "Hebrews 8:12",
  },
  {
    id: 254,
    text: "Our old life has ended and now everything is new in Christ.",
    ref: "2 Corinthians 5:17",
  },
  {
    id: 255,
    text: "Reach out and you will touch Me, for I am not far from you.",
    ref: "Acts 17:27",
  },
  {
    id: 256,
    text: "Before you even call out to Me, I will answer you.",
    ref: "Isaiah 65:24",
  },
  {
    id: 257,
    text:
      "I will be the voice behind you, guiding you in the way you should go.",
    ref: "Isaiah 30:21",
  },
  {
    id: 258,
    text: "I will train you for battle with My shield of salvation.",
    ref: "Psalm 18:34-35",
  },
  {
    id: 259,
    text: "I will be faithful to give you My strength and protection.",
    ref: "2 Thessalonians 3:3",
  },
  {
    id: 260,
    text: "You will find safety in My everlasting arms.",
    ref: "Deuteronomy 33:27",
  },
  {
    id: 261,
    text: "I carry your burdens everyday.",
    ref: "Psalm 68:19",
  },
  {
    id: 262,
    text: "I have called you to inherit My blessing.",
    ref: "1 Peter 3:9",
  },
  {
    id: 263,
    text:
      "If you honor Me with your first fruits, you will overflow with abundance.",
    ref: "Proverbs 3:9-10",
  },
  {
    id: 264,
    text: "If you help the poor, your light will shine like the dawn.",
    ref: "Isaiah 58:7-8",
  },
  {
    id: 265,
    text: "If you wait for Me, I will renew your strength.",
    ref: "Isaiah 40:31",
  },
  {
    id: 266,
    text: "I will give you a peaceful sleep surrounded by safety.",
    ref: "Psalm 4:8",
  },
  {
    id: 267,
    text: "I will enrich your life and renew your youth like the eagle’s.",
    ref: "Psalm 103:5",
  },
  {
    id: 268,
    text:
      "I will not reject the sacrifice of a broken spirit and a contrite heart.",
    ref: "Psalm 51:17",
  },
  {
    id: 269,
    text: "I will always be close when you need My comfort.",
    ref: "2 Corinthians 1:3-4",
  },
  {
    id: 270,
    text:
      "When you walk through the valley of the shadow of death, I will be with you.",
    ref: "Psalm 23:4",
  },
  {
    id: 271,
    text:
      "I will keep you from falling until you joyfully stand blameless in My presence.",
    ref: "Jude 1:24",
  },
  {
    id: 272,
    text: "I will soon crush the evil one under your feet.",
    ref: "Romans 16:20",
  },
  {
    id: 273,
    text: "You have an everlasting inheritance reserved in heaven for you.",
    ref: "1 Peter 1:3-4",
  },
  {
    id: 274,
    text: "I have the ability to do all that I have promised.",
    ref: "Romans 4:20-21",
  },
  {
    id: 275,
    text: "I am the everlasting God.",
    ref: "Psalm 90:2",
  },
  {
    id: 276,
    text: "I am righteous in all My ways and kind in all that I do.",
    ref: "Psalm 145:17",
  },
  {
    id: 277,
    text: "I am your Father, and I love you even as I love My Son, Jesus.",
    ref: "John 17:23",
  },
  {
    id: 278,
    text: "If you believe in Jesus, you will not be disappointed.",
    ref: "1 Peter 2:6",
  },
  {
    id: 279,
    text: "You are My workmanship, created in Christ to do My good works.",
    ref: "Ephesians 2:10",
  },
  {
    id: 280,
    text: "I have chosen you to be a royal priesthood and a holy nation.",
    ref: "1 Peter 2:9",
  },
  {
    id: 281,
    text: "Your life comes from Me because You are My offspring.",
    ref: "Acts 17:28",
  },
  {
    id: 282,
    text:
      "I have blessed you in Christ with one outpouring of grace after another.",
    ref: "John 1:16",
  },
  {
    id: 283,
    text:
      "If you confess your sins, I will be faithful to forgive and cleanse you.",
    ref: "1 John 1:9",
  },
  {
    id: 284,
    text: "Because of Jesus, you are free from all condemnation.",
    ref: "Romans 8:1",
  },
  {
    id: 285,
    text:
      "I am happy to treat you as My child, and I hope you will call Me Father.",
    ref: "Jeremiah 3:19",
  },
  {
    id: 286,
    text: "Even if your mother forgets you, I will never forget you.",
    ref: "Isaiah 49:15",
  },
  {
    id: 287,
    text:
      "I will fulfill My purpose for your life because My mercy endures forever.",
    ref: "Psalm 138:8",
  },
  {
    id: 288,
    text: "If you make your home in love, you live in Me and I live in you.",
    ref: "1 John 4:16",
  },
  {
    id: 289,
    text: "I love to sing over you with loud shouts of joy.",
    ref: "Zephaniah 3:17",
  },
  {
    id: 290,
    text: "If I look after the sparrows, I will certainly take care of you.",
    ref: "Luke 12:6-7",
  },
  {
    id: 291,
    text: "If you remain still, I will do your fighting for you.",
    ref: "Exodus 14:14",
  },
  {
    id: 292,
    text: "I will give you My power to destroy spiritual strongholds.",
    ref: "2 Corinthians 10:3-5",
  },
  {
    id: 293,
    text: "I will cover you with My feathers and protect you with My wings.",
    ref: "Psalm 91:4",
  },
  {
    id: 294,
    text: "My angels will surround all who fear Me.",
    ref: "Psalm 34:7",
  },
  {
    id: 295,
    text: "I will meet all your needs so you can overflow with good works.",
    ref: "2 Corinthians 9:8",
  },
  {
    id: 296,
    text: "I will give you My strength and bless you with My peace.",
    ref: "Psalm 29:11",
  },
  {
    id: 297,
    text: "I have given you My Spirit, so your body could be My temple.",
    ref: "1 Corinthians 6:19",
  },
  {
    id: 298,
    text: "My love covers multitude of sins.",
    ref: "1 Peter 4:8",
  },
  {
    id: 299,
    text: "My great peace will guard your heart and your thoughts in Jesus. ",
    ref: "Philippians 4:7",
  },
  {
    id: 300,
    text: "I watch over you and I listen for your prayers.",
    ref: "1 Peter 3:12",
  },
  {
    id: 301,
    text: "Jesus is happy to share His inheritance with you.",
    ref: "Romans 8:16-17",
  },
  {
    id: 302,
    text: "Those who overcome will not be hurt by the second death.",
    ref: "Revelation 2:11",
  },
  {
    id: 303,
    text: "I have called you to My eternal glory in Jesus Christ.",
    ref: "1 Peter 5:10",
  },
  {
    id: 304,
    text: "I am giving you a kingdom that can never be shaken.",
    ref: "Hebrews 12:28",
  },
  {
    id: 305,
    text: "I promise a crown of life to all who love Me despite their trials.",
    ref: "James 1:12",
  },
  {
    id: 306,
    text: "I am tenderhearted towards all that I have made.",
    ref: "Psalm 145:9",
  },
  {
    id: 307,
    text:
      "My love stretches to the heavens and My faithfulness extends above the clouds.",
    ref: "Psalm 36:5",
  },
  {
    id: 308,
    text: "I have chosen you to be My own precious possession.",
    ref: "Deuteronomy 7:6",
  },
  {
    id: 309,
    text: "I know everything about you and am mindful of all your ways.",
    ref: "Psalm 139:1-3",
  },
  {
    id: 310,
    text: "The spirit of life in Christ has set you free from sin and death.",
    ref: "Romans 8:2",
  },
  {
    id: 311,
    text: "Jesus bore your sins on the cross so you would be healed.",
    ref: "1 Peter 2:24",
  },
  {
    id: 312,
    text: "Draw near to Me and I will cleanse you from an evil conscience.",
    ref: "Hebrews 10:22",
  },
  {
    id: 313,
    text: "My love is patient and kind, and does not keep track of wrongs.",
    ref: "1 Corinthians 13:4-5",
  },
  {
    id: 314,
    text: "I am your light and your salvation, the strength of your life.",
    ref: "Psalm 27:1",
  },
  {
    id: 315,
    text: "I have given you the Spirit of adoption so you can call Me Father.",
    ref: "Romans 8:15",
  },
  {
    id: 316,
    text: "Come boldly to My throne when you need to find mercy and grace.",
    ref: "Hebrews 4:16",
  },
  {
    id: 317,
    text: "Taste and you will see that i am a good God.",
    ref: "Psalm 34:8",
  },
  {
    id: 318,
    text: "I wait for opportunities to show you My compassion.",
    ref: "Isaiah 30:18",
  },
  {
    id: 319,
    text: "I will shower My Spirit and My blessings on your offspring.",
    ref: "Isaiah 44:3",
  },
  {
    id: 320,
    text: "I will comfort you and My everlasting encouragement.",
    ref: "2 Thessalonians 2:16-17",
  },
  {
    id: 321,
    text: "Delight in Me and I will give you the longings of your heart.",
    ref: "Psalm 37:4",
  },
  {
    id: 322,
    text: "Just ask and you will receive.",
    ref: "Matthew 7:7",
  },
  {
    id: 323,
    text: "I look throughout the earth to strengthen those who belong to me.",
    ref: "2 Chronicles 16:9",
  },
  {
    id: 324,
    text: "I will be your safe place in difficult times.",
    ref: "Nahum 1:7",
  },
  {
    id: 325,
    text: "I will give you power over the enemy and nothing will hurt you.",
    ref: "Luke 10:19",
  },
  {
    id: 326,
    text: "If you love Me with all your heart, I will provide all you need.",
    ref: "Deuteronomy 11:13-14",
  },
  {
    id: 327,
    text: "My children will overcome the world through their faith.",
    ref: "1 John 5:4",
  },
  {
    id: 328,
    text: "I will root your heart in the depths of My love.",
    ref: "Ephesians 3:17",
  },
  {
    id: 329,
    text:
      "I will come to you as certainty as the sun rises and spring rains fall.",
    ref: "Hosea 6:3",
  },
  {
    id: 330,
    text: "I will bless the poor in spirit with My kingdom.",
    ref: "Matthew 5:3",
  },
  {
    id: 331,
    text: "I will give you beauty to replace your ashes.",
    ref: "Isaiah 61:3",
  },
  {
    id: 332,
    text: "I will be your light and you shall reign with Me forever.",
    ref: "Revelation 22:5",
  },
  {
    id: 333,
    text:
      "The eternal glory that is in store for you far surpasses your present troubles.",
    ref: "2 Corinthians 4:17",
  },
  {
    id: 334,
    text:
      "An everlasting city awaits you, for I am not ashamed to be called your God.",
    ref: "Hebrews 11:16",
  },
  {
    id: 335,
    text: "Every word of every promise that I have made will come to pass",
    ref: "1 Kings 8:56",
  },
  {
    id: 336,
    text: "I am God and Father of all creation.",
    ref: "Ephesians 4:6",
  },
  {
    id: 337,
    text: "My family in heaven and on earth is named after Me.",
    ref: "Ephesians 3:14-15",
  },
  {
    id: 338,
    text: "I created you with My own hands",
    ref: "Psalm 119:73",
  },
  {
    id: 339,
    text: "You are fearfully and wonderfully made.",
    ref: "Psalm 139:14",
  },
  {
    id: 340,
    text: "I am your God and you are the sheep of My pasture.",
    ref: "Psalm 100:3",
  },
  {
    id: 341,
    text: "As a shepherd carries a lamb, I have carried you close to My heart.",
    ref: "Isaiah 40:11",
  },
  {
    id: 342,
    text: "If your parents reject you, I will take you in.",
    ref: "Psalm 27:10",
  },
  {
    id: 343,
    text: "I am the perfect Father.",
    ref: "Matthew 5:48",
  },
  {
    id: 344,
    text: "I will not let you fall for I am watching over you day and night.",
    ref: "Psalm 121:3",
  },
  {
    id: 345,
    text: "My tender mercies never fail, for they are new every morning. ",
    ref: "Lamentations 3:22-23",
  },
  {
    id: 346,
    text: "I lavish My great love on My children.",
    ref: "1 John 3:1",
  },
  {
    id: 347,
    text: "I have made an everlasting covenant to only do good things for you.",
    ref: "Jeremiah 32:40",
  },
  {
    id: 348,
    text: "I will go before you and fight your battles.",
    ref: "Deuteronomy 1:30",
  },
  {
    id: 349,
    text: "I will keep you safe from trouble for I am your hiding place.",
    ref: "Psalm 32:7",
  },
  {
    id: 350,
    text:
      "I am your rock and your salvation, a fortress that cannot be shaken.",
    ref: "Psalm 62:2",
  },
  {
    id: 351,
    text: "You will have perfect peace if you keep focus on Me.",
    ref: "Isaiah 26:3",
  },
  {
    id: 352,
    text: "I am very close to those who have a broken heart.",
    ref: "Psalm 34:18",
  },
  {
    id: 353,
    text: "If you pray to Me in secret, I will reward you openly.",
    ref: "Matthew 6:6",
  },
  {
    id: 354,
    text: "I will provide what you need every day.",
    ref: "Matthew 6:11",
  },
  {
    id: 355,
    text: "I will seek after those who worship Me in spirit and truth.",
    ref: "John 4:23",
  },
  {
    id: 356,
    text: "I will strengthen your inner being by the power of My Spirit.",
    ref: "Ephesians 3:16",
  },
  {
    id: 357,
    text: "Cast your burdens on Me and I will look after you.",
    ref: "Psalm 55:22",
  },
  {
    id: 358,
    text: "Jesus died that you and I could be reconciled.",
    ref: "2 Corinthians 5:18-19",
  },
  {
    id: 359,
    text: "Now is the day of salvation and the time to receive My favor.",
    ref: "2 Corinthians 6:2",
  },
  {
    id: 360,
    text:
      "When I offered up My one and only Son, I gave you the best gift I could give.",
    ref: "Romans 8:32",
  },
  {
    id: 361,
    text: "Nothing in all creation will ever separate you from My love.",
    ref: "Romans 8:35-39",
  },
  {
    id: 362,
    text:
      "You are no longer a slave, for I have made you My child and My heir.",
    ref: "Galatians 4:7",
  },
  {
    id: 363,
    text:
      "My Son has made you to be a kingdom of priests to be with Me forever.",
    ref: "Revelation 1:5-6",
  },
  {
    id: 364,
    text:
      "When death is swallowed up. I will wipe away every tear from your face.",
    ref: "Isaiah 25:8",
  },
  {
    id: 365,
    text: "I will guide you to the very end, for I will be your God forever.",
    ref: "Psalm 48:14",
  },
  {
    id: 366,
    text:
      "Hold fast to the hope you confess, for I am faithful to keep My promises.",
    ref: "Hebrews 10:23",
  },
];
