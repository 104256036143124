import React, { Component } from "react";
import "./App.css";
import promises from "./promises";

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      screen: null,
      stage: null,
      promise: { id: 0, text: "" },
      about: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.toggleAbout = this.toggleAbout.bind(this);
  }

  toggleAbout(event) {
    event.preventDefault();
    this.setState({
      about: !this.state.about,
    });
  }

  handleClick(event) {
    const promiseFiltered = promises.filter(
      (item) => item.id !== this.state.promise.id
    );
    this.setState({
      screen: "promise",
      stage: "out",
    });
    setTimeout(() => {
      this.setState({
        stage: "in",
        promise:
          promiseFiltered[Math.floor(Math.random() * promiseFiltered.length)],
      });
      setTimeout(() => {
        this.setState({
          stage: "done",
        });
      }, 700);
    }, 300);
  }

  componentDidMount() {
    this.setState({
      stage: "in",
    });
    setTimeout(() => {
      this.setState({
        screen: "home",
        stage: "done",
      });
    }, 300);
  }

  render() {
    return (
      <div className={`App ${this.state.screen ? this.state.screen : ""}`}>
        <div className={`about ${this.state.about ? "visible" : ""}`}>
          <div className="content">
            <p>
              This is simply a list of God’s promises. Each of these promises
              have been paraphrased for a more universal reading/understanding.
              We encourage you to look-up each scripture reference in your own
              Bible and begin to highlight the promises as they speak to your
              heart.
            </p>
            <p>
              We also want to encourage you to turn this into an app on your
              phone (see the simple instructions below) so you can open it often
              and be reminded of God’s good promises.
            </p>
            <p>
              In Psalm 103 we are reminded to not forget the promises/benefits
              of God:
            </p>
            <blockquote>
              My soul, bless the Lord, and all that is within me, bless his holy
              name. My soul, bless the Lord, and do not forget all his benefits.
            </blockquote>
            <h2>How to turn this into an app on your phone</h2>
            <ol>
              <li>Open this webpage from your phone.</li>
              <li>
                Scroll down. Click on the icon containing an arrow in a box
              </li>
              <li>Click on "Add to Home Screen"</li>
              <li>Confirm by clicking on "Add"</li>
              <li>It should now appear on your phone as an app</li>
            </ol>
            <button type="button" onClick={this.toggleAbout}>
              Dismiss
            </button>
          </div>
        </div>
        <div className="logo-forget-not"></div>
        {this.state.screen === "home" ? (
          <button type="button" onClick={this.handleClick}>
            View Promises
          </button>
        ) : null}
        <div className={`card ${this.state.stage}`} onClick={this.handleClick}>
          <div className="promise">
            <div className="text">
              {this.state.promise ? this.state.promise.text : ""}
            </div>
            <div className="ref">
              {this.state.promise && this.state.promise.hasOwnProperty("ref")
                ? this.state.promise.ref
                : ""}
            </div>
          </div>
          <div className="instructions">click or tap</div>
        </div>

        <div className="footer">
          <div className="logo-mariners-church"></div>
          <button type="button" onClick={this.toggleAbout}>
            About/Install App
          </button>
        </div>
      </div>
    );
  }
}
